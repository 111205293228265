<template>
  <div>
    <div class="myinfo q-mt-lg q-ma-md text-h6 text-center">
      Revenue: ${{ revenue }} Fees: ${{ fee }} Income: ${{ income }} Withdraw: ${{withdraw}}
    </div>
    <div class="q-ma-md text-center">
      <!-- <q-btn> Widthdraw Request</q-btn> -->
    </div>
    <div
      class="
        q-pa-md q-gutter-md
        fit
        row
        wrap
        justify-center
        items-start
        content-start
      "
    >
      <!-- {{k}}   -->
      <q-card v-for="k in mytxns" :key="k.id" class="my-card">
        <q-card-section horizontal>
          <q-img
            class="my-nft-img q-ma-md mobile-hide"
            ratio="1"
            :src="k.nfts.data.image"
          />

          <q-card-section>
            <p class="text-h6">
              {{ k.nfts.data.name }}
              <span v-if="k.status == 'DONE'" class="text-blue">
                #{{ k.tokenid }} of {{ k.nfts.data.count }}
              </span>
            </p>
            <p v-if="k.txn" class="text-body>">
              <a :href="chainscan_url + 'tx/' + k.txn.data.transactionHash">
                Txn:
                {{
                  k.txn.data.transactionHash.substring(0, 15) +
                  "..." +
                  k.txn.data.transactionHash.substring(50)
                }}
              </a>
            </p>

            <p class="text-body">
              {{ $t("purchase_date") }}: {{ pdate(k.created_at) }}
            </p>
            <p class="text-body">{{ $t("purchase_price") }}: ${{ k.price }}</p>

            <p class="text-body">
              {{ $t("order_id") }}: {{ k.id }} {{ $t("from") }}:
              {{ k.ufrom.nickname }}
            </p>
            <p class="text-body">
              {{ $t("status") }}: {{ k.status }}
              <a :href="chainscan_url + 'address/' + k.contract">
                {{
                  k.contract.substring(0, 15) + "..." + k.contract.substring(35)
                }}
              </a>
            </p>
            <q-btn class="q-mr-md" color="blue" :to="'/nft/' + k.contract">
              {{ $t("view") }}
            </q-btn>
            <q-btn
              v-if="!k.txn && k.status =='PAID'"
              class="q-mr-md"
              color="blue"
              @click="mint_confirm = true;cur_txn = k"
            >
              {{$t('mint')}}
            </q-btn>
            <!-- <q-btn
              v-if="k.status != 'DONE'"
              color="blue"
              @click="cancel_nft(k)"
            >
              {{$t('cancel')}}
            </q-btn> -->
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
  </div>

  <q-dialog v-model="mint_confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm"
          >You are minting 1 token of NFT to your customer wallet!</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          flat
          label="Claim"
          color="orange"
          v-close-popup
          @click="mint_to_customer(cur_txn)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 800px

.my-nft-img
  min-width: 250px
  width: 250px
  height: 250px

.myinfo
  color: blue
</style>

<script>
import axios from "axios";
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { APIURL, chainscan, next_tid, next_token_id } from "../web3.js";
import { useQuasar } from "quasar";

export default {
  name: "MyTXNs",
  setup() {
    const mytxns = ref();
    const user = s.auth.user();
    const chainscan_url = ref(chainscan);
    const revenue = ref(0);
    const fee = ref(0);
    const income = ref(0);
    const withdraw = ref(0);
    onBeforeMount(async () => {
      {
        let { data: txns, error } = await s
          .from("txns")
          .select("*,nfts(data),ufrom:ufrom(*),uto:uto(*)")
          .eq("ufrom", user.id)
          .order("created_at", { ascending: false });
        console.log(txns);
        if (error) {
          console.log(error);
        } else {
          mytxns.value = txns;
        }
      }

      {
        let { data, error } = await s
          .from("v_revenue")
          .select("*")
          .eq("ufrom", user.id);
        if (error) {
          console.log(error);
        } else {
          revenue.value = data[0].revenue;
          fee.value = revenue.value * 0.15;
          income.value = revenue.value - fee.value;
        }
      }
    });

    // select sum(price) from txns where status='PAID' and ufrom = 'cfdaecc4-049b-4099-9227-8a6aed91b227'

    const q = useQuasar();

    return {
      mint_confirm: ref(false),
      cur_txn: ref({}),
      q,
      user,
      mytxns,
      chainscan_url,
      revenue,
      fee,
      income,
      withdraw
    };
  },
  methods: {
    async mint_to_customer(txn) {
      // call mint
      // reduce balance after sucess
      // change current order status
      // alert(JSON.stringify(txn));

      console.log("Call mint_to_customer", txn);

      this.q.loading.show();
      const url = APIURL + "/i/mint_token";

      const mintto = txn.to;
      // const tid = next_tid();
      const tid = txn.tid;
      // const tokenid = await next_token_id(txn.contract);
      const tokenid = txn.tokenid;

      const params = {
        to: mintto,
        contract: txn.contract,
        amount: 1,
        tid: tid,
        tokenid: tokenid,
        uuid: this.user.id,
      };
      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log("mint req:", options);
      axios(options)
        .then(async (res) => {
          console.log("mint api response:", res);
          if (res.data.blockHash != undefined) {
            this.receipt = res.data;
            console.log(this.receipt);
            // await s.transaction(async (tx) => {
            {
              const { data, error } = await s.from("tokens").insert([
                {
                  owner: mintto,
                  balance: 1,
                  data: txn.nfts.data,
                  tokenid: tokenid,
                  tid: tid,
                  contract: txn.contract,
                },
              ]);
              if (error) {
                console.log(error);
              } else {
                console.log(data);
              }
            }

            {
              // Update TXN record status to done
              const record = {
                tokenid: tokenid,
                // status: "DONE",
                txn: res,
              };
              const { data, error } = await s
                .from("txns")
                .update([record])
                .match({ id: txn.id });

              if (error) {
                console.log(error);
              } else console.log(data);
            }
          } else {
            console.log("ERR:blockchain transaction");
          }
        })
        .then(async () => {
          this.q.loading.hide();
          this.q.notify(
            "Mint 1 Token of " +
              txn.nfts.data.name +
              " to " +
              txn.to +
              " SUCCESS!"
          );

          let { data: txns, error } = await s
            .from("txns")
            .select("*,nfts(data),ufrom:ufrom(*),uto:uto(*)")
            .eq("ufrom", this.user.id)
            .order("created_at", { ascending: false });
          console.log(txns);
          if (error) console.log(error);
          this.mytxns = txns;
        });
    },
    async cancel_nft(txn) {
      alert("cancel nft" + JSON.stringify(txn));
    },
    show_nft(id) {
      this.$router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
};
</script>